import { Button, Dropdown } from "antd"
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import Logo from "./Logo";

const MainMenu = ({ btnType, useLogoIcon, logoSize, style={} }) => {
  const history = useHistory();
  const isAuthenticated = localStorage.getItem("accessToken");

  const handleSignOut = async () => {
    try {
      // await axios.post('/api/logout/');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      history.push("/signin");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }
  
  const menuItems = [
    {
      label: 'Home',
      key: 'home',
      path: '/',
      show: true
    },
    {
      label: 'Projects',
      key: 'projects',
      path: '/user/projects',
      show: isAuthenticated
    },
    {
      label: 'Account',
      key: 'account',
      path: '/user/account',
      show: isAuthenticated
    },
    // {
    //   label: 'Pricing',
    //   key: 'pricing',
    //   path: '/pricing',
    //   show: true
    // },
    {
      label: 'Contact',
      key: 'support',
      path: '/contact',
      show: true
    },
    {
      label: 'Login',
      key: 'signin',
      path: '/signin',
      show: !isAuthenticated
    },
    {
      label: 'Sign out',
      key: 'signout',
      show: isAuthenticated
    }
  ]

  return (
    <Dropdown
      menu={{
        items: menuItems.filter((item) => item.show)
          .map((item) => ({
            ...item,
            label: (
              <div style={{ width: 100, textAlign: 'center', fontFamily: ' Outfit' }}>
                {item.label}
              </div>
            ),
            onClick: () => {
              if (item.path) {
                history.push(item.path);
              } else if (item.mailto) {
                window.location.href = item.mailto;
              } else if (item.key === 'signout') {
                handleSignOut()
              } else if (item.url) {
                window.open(item.url, '_blank');
              }
            }
          }))
      }}
    >
      <Button style={{ color: 'black', padding: 0, height: useLogoIcon ? 27 : undefined, width: useLogoIcon ? 25 : undefined, ...style }} type={btnType || 'link'} >
        {
          useLogoIcon ?
          (
            <Logo color="#DB3F1D" width={23} height={23} />
          )
          : <MenuOutlined />
        }
      </Button>
    </Dropdown>
  )
}

export default MainMenu