import { Button, Form, Input } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import useScrollToTop from "../../utils/useScrollToTop.js";
import { PageContext } from "./Auth.jsx";

const Login = () => {
  const { handleRemoveFetchItems, handleSetFetchItems, showErrorMsg } = useContext(PageContext);
  const history = useHistory();
  useScrollToTop();

  const login = async (values) => {
    handleSetFetchItems('/api/login');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login/`, {
        email: values.email,
        password: values.password,
      });
      
      if (response.data.token) {
        localStorage.setItem('accessToken', response.data.token);
        localStorage.setItem('email', values.email);
        history.push("/user/projects");
      } else {
        showErrorMsg("Login failed. Please check your credentials.");
      }
    } catch (error) {
      showErrorMsg(error.response?.data?.error || "An error occurred during login.");
    } finally {
      handleRemoveFetchItems('/api/login');
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: '100%',
      }}
    >
      <Form
        name="basic"
        style={{
          width: "100%",
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={login}
        autoComplete="off"
      >
        <div style={{ marginBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <h3>Login</h3>
        </div>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
            {
              type: 'email',
              message: 'Please enter a valid email address!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;