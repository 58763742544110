import React from 'react';
import { Button, Card } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import "./index.css";

const ProjectCard = ({ project, deleteProject }) => {
  const history = useHistory();
  return (
    <Card onClick={() => { history.push(`/user/project/${project.id}/edit`) }} size='small' style={{ cursor: 'pointer', maxWidth: 200, minWidth: 100, border: 'none', padding: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', gap: 20 }}>
          <FolderOutlined style={{ fontSize: 30, fontWeight: 100 }} />
          <span style={{ textAlign: 'center' }}>{project.title}</span>
        </div>
      </div>
    </Card>
  )
}

export default ProjectCard;
