import React from 'react';
import { Button, Dropdown } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/lib/menu';

interface MenuItem {
  key: string;
  label: string;
  show: boolean;
  onClick: () => void;
  [key: string]: any; // For any additional properties
}

interface NodeMenuProps {
  items: MenuItem[];
}

const NodeMenu: React.FC<NodeMenuProps> = ({ items: menuItems }) => {
  const dropdownItems: MenuProps['items'] = menuItems
    .filter((item) => item.show)
    .map((item) => ({
      ...item,
      label: (
        <div style={{ textAlign: 'center', width: '100%' }}>
          {item.label}
        </div>
      ),
      onClick: item.onClick
    }));

  return (
    <Dropdown
      trigger="click"
      menu={{
        items: dropdownItems
      }}
    >
      <Button style={{ color: 'black', padding: 0 }} type='link'>
        <MenuOutlined />
      </Button>
    </Dropdown>
  );
};

export default NodeMenu;