import {
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnNodesChange,
  OnEdgesChange,
  applyNodeChanges,
  applyEdgeChanges,
  XYPosition,
} from 'reactflow';
import create from 'zustand';
import { nanoid } from 'nanoid/non-secure';

import { NodeData } from './MindMapNode';

export type RFState = {
  nodes: Node<NodeData>[];
  edges: Edge[];
  modalId: string,
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  updateNodeLabel: (nodeId: string, label: string) => void;
  openContentModal: (nodeId: string) => void;
  addChildNode: (parentNode: Node, position: XYPosition) => void;
  ownerEmail: string
  reset: () => void;
};

const useStore = create<RFState>((set, get) => ({
  ownerEmail: "",
  nodes: [
    // {
    //   id: 'root',
    //   type: 'mindmap',
    //   data: { label: 'React Flow Mind Map' },
    //   position: { x: 0, y: 0 },
    //   dragHandle: '.dragHandle',
    // },
  ],
  edges: [],
  modalId: '',
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  updateNodeLabel: (nodeId: string, label: string) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          // it's important to create a new object here, to inform React Flow about the changes
          node.data = { ...node.data, label };
        }

        return node;
      }),
    });
  },
  openContentModal: (nodeId: string) => {
    set({
      modalId: nodeId
    });
  },
  setOwnerEmail: (email: string) => {
    set({
      ownerEmail: email,
    });
  },
  addChildNode: (parentNode: Node, position: XYPosition) => {
    const newNode = {
      id: nanoid(),
      type: 'mindmap',
      data: { label: 'New Node' },
      position,
      dragHandle: '.dragHandle',
      parentNode: parentNode.id,
    };

    const newEdge = {
      id: nanoid(),
      source: parentNode.id,
      target: newNode.id,
    };

    set({
      nodes: [...get().nodes, newNode],
      edges: [...get().edges, newEdge],
    });
  },
  setNodes: (nodes: Node<NodeData>[]) => {
    set({ nodes });
  },
  setEdges: (edges) => {
    set({ edges });
  },
  reset: () => {
    set({
      nodes: [],
      modalId: "",
      edges: [],
      ownerEmail: ""
    })
  }
}));

export default useStore;
