import { useLayoutEffect, useEffect, useRef, useContext } from 'react';
import { Handle, NodeProps, Position, useStoreApi } from 'reactflow';
import { HolderOutlined, MenuOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { memo } from 'react';

import useStore, { RFState } from '../store.ts';
import NodeMenu from '../NodeMenu/index.tsx'

import { get, patch, post } from '../../../utils/fetch';
import { PageContext, usePageActionHandler } from "../../../components/Page";
import { actionTypes } from '../../../actions';

function deleteItemAndDescendants(items, idToDelete) {
  function getDescendantIds(id) {
    let descendants = [];
    items.forEach(item => {
      if (item.parentNode === id) {
        descendants.push(item.id);
        descendants = descendants.concat(getDescendantIds(item.id));
      }
    });
    return descendants;
  }

  const idsToDelete = [idToDelete, ...getDescendantIds(idToDelete)];
  return items.filter(item => !idsToDelete.includes(item.id));
}

export type NodeData = {
  label: string;
};

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  modalId: state.modalId,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  addChildNode: state.addChildNode,
  openContentModal: state.openContentModal,
  ownerEmail: state.ownerEmail
});

function MindMapNode({ id, data }: NodeProps<NodeData>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { folderId, viewMode } = useParams();
  const { addAction, removeAction } = usePageActionHandler();
  const { setError } = useContext(PageContext);
  const updateNodeLabel = useStore((state) => state.updateNodeLabel);
  const store = useStoreApi();
  const userEmail = localStorage.getItem('email');
  const { nodes, edges, ownerEmail, openContentModal} = useStore(
    selector,
    shallow
  );
  const isEditMindmapMode = viewMode === 'edit';

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({ preventScroll: true });
    }, 1);
  }, []);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (data.label.length < 5) {
        inputRef.current.style.width = `${data.label.length * 10}px`;
        return
      } else if (data.label.length < 10) {
        inputRef.current.style.width = `${data.label.length * 9}px`;
        return
      } else {
        inputRef.current.style.width = `${data.label.length * 8.2}px`;
        return
      }
    }
  }, [data.label.length]);

  const handleNodeDelete = (id) => {
    const newNodes = deleteItemAndDescendants(nodes, id)
    const { setNodes } = useStore.getState();
    setNodes(newNodes);
  }

  const updateProject = async () => {
    const action = "getMindmaps";
    addAction(action);
    const newNodesData = nodes.map((item) => ({
      id: item.dbId || item.id,
      title: item.data.label,
      parent: item.parentNode === 'root'
        ? nodes.find((item) => item.id === 'root').dbId
        : item.parentNode,
      flow_data: JSON.stringify({
        data: item.data,
        dragHandle: item.dragHandle,
        height: item.height,
        id: item.id,
        parentNode: item.parentNode,
        position: item.position,
        type: item.type,
        width: item.width,
        positionAbsolute: item.positionAbsolute
      })
    }))
    await patch(`${actionTypes[action].api}${folderId}/`, {
      title: newNodesData[0].title,
      nodes: newNodesData,
    })
      .then((res) => {
        
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const handleAutoGenerateChildren = async (id) => {
    const action = "nodes";
    addAction(action);
    const nodesPositions = nodes.map((item) => ({
      id: item.id,
      title: item.data.label,
      parentNode: item.parentNode,
      position: item.position,
      width: item.width,
      height: item.height
      // positionAbsolute: item.positionAbsolute
    }))
    const nodeData = nodes.find((item) => item.id === id);
    const nodeId = nodeData.dbId || nodeData.id;
    
    await post(`${actionTypes[action].api}${nodeId}/auto-generate-children/`, {
      nodes_position: nodesPositions,
      ai_key: localStorage.getItem('openAiApiKey'),
      ai_model: localStorage.getItem('aiModel'),
      num_children: 3
    })
      .then((res) => {
        const newNodes = res.children.map((item) => ({
          data: { label: item.title },
          dragHandle: '.dragHandle',
          // height: 32,
          id: item.id,
          parentNode: id,
          position: {"x":item.x,"y":item.y},
          type: 'mindmap',
          // width: 118,
          positionAbsolute: {"x":item.absolute_x,"y":item.absolute_y}
        }))

        const newEdges = res.children.map((item) => ({
          id: uuidv4().replace(/-/g, '').slice(0, 20),
          source: id,
          target: item.id
        }))

        const { setNodes, setEdges } = useStore.getState();
        setNodes([...nodes, ...newNodes]);
        setEdges([...edges, ...newEdges])
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const getDbId = (id) => {
    return nodes.find((item) => item.id === id).dbId || id;
  }

  return (
    <div>
      <div className="inputWrapper">
        <div className="dragHandle">
          <HolderOutlined />
        </div>
          <div className="viewHandle">
            <NodeMenu
              items={[
                {
                  label: 'Note',
                  key: 'home',
                  onClick: async () => {
                    openContentModal(getDbId(id))
                  },
                  show: true
                },
                {
                  label: 'Break it down!',
                  key: 'elaborate',
                  onClick: async () => {
                    await updateProject();
                    await handleAutoGenerateChildren(id)
                  },
                  show: true,
                  disabled: !isEditMindmapMode
                },
                {
                  label: 'Delete',
                  key: 'delete',
                  onClick: () => { handleNodeDelete(id) },
                  show: true
                },
              ]}
            />
          </div>
        <input
          defaultValue={data.label}
          onChange={(evt) => updateNodeLabel(id, evt.target.value)}
          className="input"
          style={{ textAlign: 'center' }}
          ref={inputRef}
        />
      </div>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Top} />
    </div>
  );
}


export default memo(MindMapNode);
