import { Button, Card, Input, Tag, Radio } from "antd";
import { useContext, useRef, useState } from "react";

import { AppContext } from "../../App";
import { PageContext, usePageActionHandler } from '../../components/Page';
import CommonPage from "../../components/CommonPage"
import { post } from "../../utils/fetch";

const AccountPage = () => {
  const { setError, isActionInProgress } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const openAiKeyInputRef = useRef(null);
  let openAiApiKey = localStorage.getItem('openAiApiKey');
  let currentAiModel = localStorage.getItem('aiModel');
  const [saveApiKeysSuccess, setSaveApiKeysSuccess] = useState(false);
  const [aiModel, setAiModel] = useState(currentAiModel || "gpt-4o-mini");

  const handleSaveApiKeys = async () => {
    const api = `/verify-ai-key/`;
    addAction(api)
    await post(api, {
      key: openAiKeyInputRef.current.input.value,
    })
      .then((res) => {
        if (openAiKeyInputRef.current.input.value) {
          localStorage.setItem('openAiApiKey', openAiKeyInputRef.current.input.value);
          localStorage.setItem('aiModel', aiModel);
        } else {
          localStorage.removeItem('openAiApiKey');
          localStorage.removeItem('aiModel');
        }
        setSaveApiKeysSuccess(true);
      })
      .catch((err) => {
        setError(err)
      })
      removeAction(api)
  }

  return (
    <CommonPage title="Account">
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', gap: 20 }}>
            <h3>Profile</h3>
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <span>Registered email:</span>
            <span>{localStorage.getItem("email")}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', gap: 20, alignItems: 'baseline' }}>
            <h3>OpenAI API key</h3>
          </div>
          <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
            {
              localStorage.getItem('openAiApiKey') &&
              <Radio.Group onChange={(val) => { setAiModel(val.target.value) }} value={aiModel}>
                <Radio value="gpt-4o-mini">GPT-4o mini</Radio>
                <Radio value="gpt-4o">GPT-4o</Radio>
              </Radio.Group>
            }
            <Input
              defaultValue={openAiApiKey}
              ref={openAiKeyInputRef}
              onClick={() => { setSaveApiKeysSuccess(false) }}
              style={{ maxWidth: 600 }}
              placeholder="Your key"
              allowClear
            />
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              <Button
                disabled={isActionInProgress('/update-api-keys')}
                loading={isActionInProgress('/update-api-keys')}
                style={{ alignSelf: 'flex-start' }}
                onClick={() => {
                  if (openAiKeyInputRef.current.input?.value?.length > 5) {
                    handleSaveApiKeys()
                  } else {
                    localStorage.removeItem('aiModel')
                    localStorage.removeItem('openAiApiKey')
                    setSaveApiKeysSuccess(true)
                  }
                }}
              >
                Save
              </Button>
              {
                (saveApiKeysSuccess) &&
                <span style={{ color: 'green' }}>Success</span>
              }
            </div>
          </div>
        </div>
      </Card>
    </CommonPage>
  )
}

export default AccountPage;
