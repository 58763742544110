
import ProtectPage from './components/ProtectPage';
import Page from "./components/Page";

import HomePage from "./pages/home/Home.jsx";
import Mindmap from "./pages/mindmap/index.tsx";
import Signin from './pages/auth/Auth';
import Projects from "./pages/projects/Projects";
import AddNewProject from './pages/projects/AddNewProject';
import AccountPage from './pages/account/Account';
import Support from './pages/support/Support.jsx'

// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

// const Redirect = () => {
//   const history = useHistory()

//   return history.push('/user/projects')
// }

const routeConfig = [{
  route: "/",
  components: [
    { component: HomePage, props: { exact: true } }
  ]
}, {
  route: "/mindmap",
  components: [
    { component: Mindmap, props: { exact: true } }
  ]
}, {
  route: "/signin",
  components: [
    { component: Signin, props: { exact: true } }
  ]
}, {
  route: "/user/projects",
  components: [
    ProtectPage,
    { component: Page },
    { component: Projects, props: { exact: true } }
]}, {
  route: "/user/project/:folderId/:viewMode",
  components: [
    // ProtectPage,
    { component: Page, props: { showTopLoadingBar: false } },
    { component: Mindmap, props: { exact: true } }
]}, {
  route: "/user/projects/new",
  components: [
    ProtectPage,
    Page,
    { component: AddNewProject, props: { exact: true } }
]}, {
  route: "/user/account",
  components: [
    ProtectPage,
    { component: Page },
    { component: AccountPage, props: { exact: true } }
]}, {
  route: "/contact",
  components: [
    Support
]}]

export default routeConfig;
