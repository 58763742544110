import React from 'react';
import { Panel } from 'reactflow';
import { Button, Spin } from "antd";
import { Input } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import io from "socket.io-client";

import { get, patch, post } from '../../../utils/fetch';
import { PageContext, usePageActionHandler } from "../../../components/Page";
import { actionTypes } from '../../../actions';
import Markdown from "../../../components/Markdown";
import CodeBlock from "../../../components/CodeBlock";

const { TextArea } = Input;

interface DetailBoxProps {
  modalId: string;
  openContentModal: (id: string) => void;
}

const defaultInstruction = "Auto generate note for this topic."

const DetailBox: React.FC<DetailBoxProps> = ({ modalId, updateProject, isEditMindmapMode, openContentModal }) => {
  const { addAction, removeAction } = usePageActionHandler();
  const { setError, isActionInProgress } = React.useContext(PageContext);

  const [editMode, setEditMode] = React.useState(false);
  const [instruction, setInstruction] = React.useState(defaultInstruction)
  const [text, setText] = React.useState('');
  const [draftText, setDraftText] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [draftTitle, setDraftTitle] = React.useState('')
  const [nodeData, setNodeData] = React.useState({});

  React.useEffect(() => {
    const socket = io("https://socket.chat2course.com");
    let streamText = '';
    if (nodeData?.id && isEditMindmapMode) {
      socket.emit('subscribeToStatusUpdate', modalId);
      socket.on('statusUpdate', (socketData) => {
        const { data } = socketData;
        if (data.error) {
          console.error({ data });
          return
        }
        
        if (data.action === 'notaminda-node-auto-generate-note' && data.data?.reply) {
          setText(data.data.reply)
          streamText = data.data.reply;
        }

        if (data.action === 'notaminda-node-auto-generate-note-finished') {
          updateNode(streamText, title)
          removeAction('streaming')
        }
      });
    }
    return () => {
      socket.disconnect();
    };
  }, [nodeData?.id])

  React.useEffect(() => {
    getNode();
  }, [modalId])
  
  const getNode = async () => {
    if (isEditMindmapMode) {
      await updateProject()
    }
    const action = isEditMindmapMode ? "nodes" : "publicNodes";
    addAction(action);
    await get(`${actionTypes[action].api}${modalId}/`, isEditMindmapMode)
      .then((res) => {
        setNodeData(res)
        setText(res.note)
        setTitle(res.title || res.flow_data?.data?.label)
      })
      .catch((err) => {
        if (isEditMindmapMode) {
          setError(err);
        }
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const updateNode = async (textToSave, title) => {
    const action = "nodes";
    addAction(action);
    await patch(`${actionTypes[action].api}${modalId}/`, {
      title: title,
      note: textToSave
    })
      .then((res) => {
        setText(textToSave)
        setTitle(title)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const autoGenerateNote = async () => {
    const action = "nodes";
    setEditMode(false)
    addAction(action);
    await post(`${actionTypes[action].api}${modalId}/auto-generate-note/`, {
      instruction: (instruction && instruction.length > 3 && (instruction === defaultInstruction ? undefined : instruction)) || undefined,
      node_id: modalId,
      ai_key: localStorage.getItem('openAiApiKey'),
      ai_model: localStorage.getItem('aiModel'),
    })
      .then((res) => {
        // setText(draftText)
        addAction('streaming')
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <Panel position="bottom-right" style={{ boxShadow: "rgba(0, 0, 0, 0.2) -9px 1px 9px 0px" }}>
      <div style={{ borderRadius: 8, height: window.innerWidth > 900 ? 'calc(100vh - 12px)' : 'calc(100vh - 49px)', width: window.innerWidth > 900 ? '50vw' : 'calc(100vw - 44px)', maxWidth: 900, backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4, position: 'absolute', left: -14, top: 56 }}>
          <Button
            style={{ backgroundColor: 'white' }}
            disabled={isActionInProgress()}
            onClick={() => {
              if (editMode) {
                setEditMode(false)
              } else {
                openContentModal('') 
              }
            }} 
            icon={<CloseOutlined />}
          />
          <Button
            style={{ backgroundColor: 'white' }}
            disabled={(editMode && !isEditMindmapMode) || isActionInProgress()}
            onClick={() => {
              if (editMode) {
                updateNode(draftText, draftTitle)
              } else {
                setDraftText(text)
                setDraftTitle(title)
                setEditMode(!editMode)
              }
            }}
            icon={editMode ? <SaveOutlined /> : <EditOutlined />}
          />
        </div>
        {isActionInProgress() && <div style={{ position: 'absolute', right: 10, top: 10, display: 'flex', justifyContent: 'right', padding: 10 }}><Spin tip="Loading..." /></div>}
          <div style={{ height: '-webkit-fill-available', padding: '40px 20px 40px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {
              !editMode && nodeData.flow_data?.data?.label !== title &&
              <h3 style={{ padding: '0 0px 0 20px', margin: 0, fontWeight: 600, fontSize: 15 }}>{nodeData.flow_data?.data?.label}</h3>
            }
            {
              editMode
              ? (
                <div style={{ padding: '0 0px 10px 10px' }}>
                  <Input style={{ fontSize: 32, fontWeight: 700, padding: '20px' }} value={draftTitle} onChange={(val) => { setDraftTitle(val.target.value) }} />
                </div>
              )
              : (
                <h1 style={{ marginLeft: 20 }}>
                  {title}
                </h1>
              )
            }
            {
              editMode
              ? <div style={{ padding: '0 0px 10px 10px', height: '100%', overflow: 'auto' }}>
                <TextArea value={draftText} onChange={(val) => { setDraftText(val.target.value) }} style={{ fontSize: 16, padding: '20px', height: '100%', overflow: 'auto', fontFamily: 'Source Serif Pro' }} />
                </div>
              : (
                <div style={{ padding: '0 20px 20px 20px', height: '100%', overflow: 'auto' }}>
                  <Markdown className='note-markdown' components={{ code: CodeBlock }}>
                    {text}
                  </Markdown>
                </div>
              )
            }
            {
              !editMode && (
                <div style={{ marginLeft: 20, display: 'flex', justifyContent: 'space-between', gap: 4, marginTop: 10 }}>
                  <Input value={instruction} onChange={(val) => { setInstruction(val.target.value) }} placeholder="Enter your instruction" />
                  <Button onClick={autoGenerateNote} disabled={!isEditMindmapMode || isActionInProgress()}><SendOutlined /></Button>
                </div>
              )
            }
          </div>
      </div>
    </Panel>
  );
};

export default DetailBox;