import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card } from "antd";

import MainMenu from './MainMenu';
import Logo from "./Logo";

const CommonPage = ({ title, extraHeader, children }) => {

  return (
<div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 10 }}>
  <div style={{ display: 'flex', justifyContent: 'space-between', height: 40, alignItems: 'center', margin: '0 2px' }}>
    <Link to="/">
      <Logo width={32} height={32} />
    </Link>
    <MainMenu />
  </div>
  <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4, marginTop: 6 }}>
    <h2 style={{ fontFamily: ' Outfit' }}>{title}</h2>
    {extraHeader}
  </Card>
  {children}
</div>
  )
}

export default CommonPage;
