import ReactMarkdown from "react-markdown";
import './markdown.css'

const Markdown = ({ className="overview-markdown", content, children, components }) => {
  return (
    <div className={className}>
      <ReactMarkdown components={components}>
        {content || children}
      </ReactMarkdown>
    </div>
  )
}

export default Markdown;
