import axios from "axios";
import { isValidUrl } from './utils';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const refreshAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
});

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    
    failedQueue = [];
};

const redirectToLogin = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/signin';  // Adjust this URL as needed
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (originalRequest.url === '/token/refresh/') {
            redirectToLogin();
            return Promise.reject(error);
        }

        console.error({ err1: error.response.status, retry: !originalRequest._retry, isRefreshing })
        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axiosInstance(originalRequest);
                }).catch(err => {
                  console.log({ err2: err });
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const { access } = await refreshToken();
                localStorage.setItem('accessToken', access);
                axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access;
                originalRequest.headers['Authorization'] = 'Bearer ' + access;
                processQueue(null, access);
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                processQueue(refreshError, null);
                redirectToLogin();
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

async function refreshToken() {
  const refresh = localStorage.getItem('refreshToken');
  try {
      const response = await refreshAxiosInstance.post('/token/refresh/', { refresh });
      return response.data;
  } catch (error) {
    console.log({ err: error.response });
    
      if (error.response && error.response.status === 401) {
          redirectToLogin();
      }
      throw error;
  }
}

async function get(url, useAuth = true) {
  const apiEndpoint = isValidUrl(url) ? url : url;
  
  try {
      const config = {};
      if (!useAuth) {
          config.headers = { Authorization: null };
      }
      const response = await axiosInstance.get(apiEndpoint, config);
      return response.data;
  } catch (err) {
      if (err?.response?.status === 429) {
          return { error: err?.response?.data?.detail || 'Too many requests' };
      }
      throw err;
  }
}

async function post(url, body = {}, useAuth = true) {
  const apiEndpoint = isValidUrl(url) ? url : url;
  
  try {
      const config = {};
      if (!useAuth) {
          config.headers = { Authorization: null };
      }
      const response = await axiosInstance.post(apiEndpoint, body, config);
      return response.data;
  } catch (err) {
      if (err?.response?.status === 429) {
          return { error: err?.response?.data?.detail || 'Too many requests' };
      }
      throw err;
  }
}

async function patch(url, body = {}, useAuth = true) {
  const apiEndpoint = isValidUrl(url) ? url : url;
  
  try {
      const config = {};
      if (!useAuth) {
          config.headers = { Authorization: null };
      }
      const response = await axiosInstance.put(apiEndpoint, body, config);
      return response.data;
  } catch (err) {
      if (err?.response?.status === 429) {
          return { error: err?.response?.data?.detail || 'Too many requests' };
      }
      throw err;
  }
}

export {
    post,
    get,
    patch
};