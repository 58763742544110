import { Button, Form, Input } from "antd";
import { GoogleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import { post } from "../../utils/fetch"
import { useContext } from "react";
import useScrollToTop from "../../utils/useScrollToTop.js";

import { PageContext } from "./Auth.jsx";

const Register = ({ hideRegister }) => {
  useScrollToTop()
  const { handleRemoveFetchItems, handleSetFetchItems, showErrorMsg } = useContext(PageContext);
  const history = useHistory();
  const [form] = Form.useForm();

  const createUser = async (values) => {
    const api = `/register/`;
    handleSetFetchItems(api);
    try {
      await post(api, { 
        email: values.email,
        password: values.password,
      })
      const token = await post ('/token/', {
        username: values.email,
        password: values.password
      })
      localStorage.setItem('email', values.email);
      localStorage.setItem('accessToken', token.access);
      localStorage.setItem('refreshToken', token.refresh);
      history.push(`/user/projects`)
    } catch (error) {
      showErrorMsg(error);
    }
    handleRemoveFetchItems(api);
  }

  const signUp = async (values) => {
    await createUser(values);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        flexDirection: 'column',
        height: 600,
        width: '100%',
      }}
    >
      <Form
        form={form}
        name="basic"
        style={{
          width: "100%",
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={hideRegister ? createUser : signUp}
        autoComplete="off"
      >
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.referral !== currentValues.referral}
        >
          {({ getFieldValue, values }) =>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
              <>
                <div style={{ marginBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                  <h3>Register</h3>
                </div>
                <Form.Item
                  label=""
                  name="email"
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  label=""
                  name="password"
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </>
            }
            <Form.Item
              style={{ marginLeft: 'auto' }}
            >
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </div>
          }
        </Form.Item>
      </Form>
    </div>
  )
}

export default Register;