/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import { Alert, Button, Progress, Space, message } from 'antd';

import ErrorMessage from './ErrorMessage';
import { AppContext } from '../App';
import { actionTypes } from '../actions';

const PageContext = createContext(null);

const usePageActionHandler = () => {
  const context = useContext(PageContext);
  const { addPageAction, removePageAction } = context;

  const addAction = (api, msg) => addPageAction(api, msg);
  const removeAction = (api, msg) => removePageAction(api, msg);

  return { addAction, removeAction };
};

const Page = ({ showTopLoadingBar=true, initialActions=[], children }) => {
  const { appActions } = useContext(AppContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [activePageActions, setActivePageActions] = useState([]);
  const [error, setError] = useState(null);
  const [actionConfirmation, setActionConfirmation] = useState({
    open: false,
    message,
    callback: ()=>{}
  })

  useEffect(() => {
    Promise.all(
      initialActions.map(async (action) => {
        addPageAction(action, actionTypes[action].fetchMessage);
        await appActions[action]();
        removePageAction(action);
      })
    )
  }, [])
  
  const addPageAction = (api, msg) => {
    setActivePageActions((prevActions) => {
      if (showTopLoadingBar && prevActions.length === 0) {
        messageApi.open({
          type: 'loading',
          content: msg || 'Action in progress..',
          duration: 0,
        });
      }
      return [...prevActions, { api, msg }]
    });
  }

  const removePageAction = (api) => {
    setActivePageActions((prevActions) => {
      messageApi.destroy()
      if (showTopLoadingBar && prevActions.length > 1) {
        const latestMsg = prevActions[0].api === api
          ? prevActions[1].msg
          : prevActions[0]?.msg
        messageApi.open({
          type: 'loading',
          content: latestMsg || 'Action in progress..',
          duration: 0,
        });
      }
      return prevActions.filter((action) => action.api !== api)
    });
  }

  const isActionInProgress = (api) => {
    if (!api) {
      return !!activePageActions.length;
    }

    if (Array.isArray(api)) {
      return api.some(action => {
        return activePageActions.some(activeAction => activeAction.api === action)
      });
    }
  
    return activePageActions.some(activeAction => activeAction.api === api);
  }

  const withConfirmation = async (action, callback, needConfirmation=true) => {
    if (needConfirmation) {
      setActionConfirmation({
        open: true,
        message: actionTypes[action].confirmationText,
        callback: async () => {
          resetConfirmationState();
          await callback(action);
        }
      })
      return;
    }

    await callback(action);
  }

  const resetConfirmationState = () => {
    setActionConfirmation({
      open: false,
      message: null,
      callback: ()=>{}
    })
  }

  return (
    <PageContext.Provider
      value={{
        isActionInProgress,
        addPageAction,
        removePageAction,
        setError: (err) => {
          if (typeof err === 'object') {
            setError({
              ...err,
              timestamp: Math.floor(Date.now() / 1000)
            })
          } else {
            setError({
              error: err,
              timestamp: Math.floor(Date.now() / 1000)
            })
          }
        },
        withConfirmation,
        resetConfirmationState
      }}
    >
      {
        showTopLoadingBar &&
        <Progress
          style={{ margin: '-8px 0 0 0', height: 14 }}
          strokeColor="#0390ff"
          size="small"
          showInfo={false}
          percent={activePageActions.length ? (100 / (activePageActions.length + 1)) : 100}
        />
      }
      <>
        <ErrorMessage error={error} />
        {children}
        {contextHolder}
        {
          actionConfirmation.open && (
            <div style={{ position: 'absolute', width: 'calc(100% - 10px)', bottom: 0, margin: 5, zIndex: 1001 }}>
              <Alert
                description={actionConfirmation.message}
                type="info"
                action={
                  <Space direction={window.innerWidth > 800 ? "horizontal" : "vertical"}>
                    <Button
                      style={{ width: 68 }}
                      onClick={actionConfirmation.callback}
                      size="small"
                      type="primary"
                    >
                      Confirm
                    </Button>
                    <Button style={{ width: 68 }} onClick={resetConfirmationState} size="small" danger ghost>
                      Cancel
                    </Button>
                  </Space>
                }
              />
            </div>
          )
        }
      </>
    </PageContext.Provider>
  );
};

export {
  PageContext,
  usePageActionHandler
};

export default Page;
