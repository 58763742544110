import { Route, Switch } from 'react-router-dom';
import React, { createContext, useState } from 'react';

import './App.css';
import routeConfig from './routeConfig';
import routing from './utils/routing';
// import { post } from './utils/fetch';
// import { actionTypes } from './actions';

const AppContext = createContext(null);

const App = () => {
  const [appState, setAppState] = useState({});

  return (
    <AppContext.Provider
      value={{
        appState,
        appActions: {
        },
      }}
    >
      <div className="App" style={{ width: '100%', height: '100%' }}>
        <Switch>
          {routeConfig.map((config, index) => (
            <Route
              key={index}
              path={config.route}
              exact={config.components.some(component => component.props && component.props.exact)}
            >
              {routing.renderNestedComponents(config)}
            </Route>
          ))}
        </Switch>
      </div>
    </AppContext.Provider>
  );
}

export { AppContext };
export default App;
