import { createContext, useState } from "react";
import { Button, Alert } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

import './auth.css';
import Login from "./Login.jsx";
import Register from "./Register.jsx";
import LoadingMessage from "../../components/Loading.jsx";
import ErrorMsg from "../../components/ErrorMessage.jsx";
import useScrollToTop from "../../utils/useScrollToTop.js";
import MainMenu from '../../components/MainMenu';
import Logo from "../../components/Logo.jsx";

const PageContext = createContext(null);

const App = () => {
  const [fetchItems, setFetchItems] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSetFetchItems = (api) => {
    setFetchItems((prevFetchItems) => [...prevFetchItems, api]);
  }

  const handleRemoveFetchItems = (api) => {
    setFetchItems((prevFetchItems) => prevFetchItems.filter((item) => item !== api));
  }

  return (
    <PageContext.Provider value={{ handleSetFetchItems, handleRemoveFetchItems, showErrorMsg: setErrorMsg }}>
      <>
        <ErrorMsg error={errorMsg} />
        <LoadingMessage fetchItems={fetchItems} />
        <Signin />
      </>
    </PageContext.Provider>
  );
};

export { PageContext };

const Signin = () => {
  useScrollToTop()
  const [signinAction, setSigninAction] = useState('register');
  const [hideRegister, setHideRegister] = useState(false);

  return (
    <div style={{ minHeight: "100vh", margin: window.innerWidth > 600 ? 40 : 20, display: "flex", alignItems: "baseline", justifyContent: "space-evenly" }}>
      <div style={{ display: "flex", flexDirection: 'column', width: "100%", gap: 50, flexWrap: 'wrap', maxWidth: 900 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Link to="/">
            <Logo width={40} height={40} />
          </Link>
          <MainMenu />
        </div>
        <h1 style={{ textAlign:'center', fontSize: 32 }}>Sign in</h1>
        <div style={{ maxWidth: 600, margin: 'auto', width: '100%' }}>
          <div style={{ display: 'flex', gap: 10, flexDirection: 'column', alignItems: 'center' }}>
            <Button style={{ width: '100%', color: signinAction === 'login' ? '#1677ff' : undefined }} onClick={() => { setSigninAction('login') }}>Login</Button>
            <Button style={{ width: '100%', color: signinAction === 'register' ? '#1677ff' : undefined }} onClick={() => { setSigninAction('register') }}>Register</Button>
          </div>
        </div>
        {
          signinAction === 'register' &&
          <Register hideRegister={hideRegister} />
        }
        {
          signinAction === 'login' &&
          <Login setSigninAction={setSigninAction} setHideRegister={setHideRegister} />
        }
      </div>
    </div>
  )
}

export default App;

