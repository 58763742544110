import React from 'react';
import { Button, Input } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import background from '../../svg/bg.svg'

const Circle = ({ text }) => {
  return (
    <div style={{ border: '1px solid black', borderRadius: 100, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontSize: 20, fontWeight: 700, textAlign: 'center' }}>
        {text}
      </div>
    </div>
  )
}

const HomePage = () => {
  const history = useHistory();
  const isAuthenticated = localStorage.getItem("accessToken");

  return (
    <div>
      <div style={{ padding: 20, display: 'flex', justifyContent: 'right' }}>
        <Button type='link' onClick={() => { history.push('/contact') }}>Contact</Button>
        <Button type='primary' onClick={() => { history.push(isAuthenticated ? '/user/projects' : `/signin`) }} style={{ color: 'white' }}>
          {isAuthenticated ? 'Dashboard' : 'Sign in'}
        </Button>
      </div>
      <div
        style={{
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${background})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
        }}
      >
        <h1 style={{ fontSize: window.innerWidth > 900 ? 90 : 50, fontFamily: 'Times New Roman' }}>Notaminda</h1>
        <div style={{ fontSize: 26, textAlign: 'center', padding: 20 }}>AI Powered Mind Map Generator</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%', padding: 16, maxWidth: 900, position: 'relative', display: 'flex', justifyContent: 'center' }}>
          <img
            style={{
              width: '100%',
              padding: '4px',
              borderRadius: '12px',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px 0px',

            }}
            src="/hero.png"
          />
          <div style={{ position: 'absolute', top: -6, right: 26, display: 'flex', gap: 5 }}>
            <a href="https://github.com/rszhd/notaminda-rest-api">
              <Button style={{ border: '1px solid #1677ff' }} size='large'>Github</Button>
            </a>
            <Button type='primary' onClick={() => { history.push('user/project/bf77326e-773f-4676-abc8-9d165aadbc81/preview') }} size='large'>Try now</Button>
          </div>
        </div>
      </div>
      <h1 style={{
        textAlign: 'center',
        fontFamily: 'Times New Roman',
        margin: '120px 0 100px 0',
        fontSize: window.innerWidth > 900 ? 50 : 40,
        textDecoration: 'underline'
      }}>
        Features
      </h1>
      <div style={{ textAlign: 'center', maxWidth: 520, padding: '0 30px', margin: 'auto', marginBottom: 45, }}>
        <h1 style={{ fontFamily: 'Times New Roman', }}>Break down your topic</h1>
        <p style={{ fontSize: 20 }}>Add child branches manually or break them down using AI</p>
      </div>
      <div style={{
        maxWidth: 900, margin: 'auto', marginBottom: 100,
        display: 'flex', justifyContent: 'center',
        padding: 16
      }}>
        <img
          src="/video/gif5.gif"
          style={{
            width: '100%',
            maxWidth: 900,
            height: '100%',
            padding: '4px',
            borderRadius: '12px',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px 0px'
            // objectFit: 'cover',
            // objectPosition: 'center'
          }}
        />
      </div>
      <div style={{ textAlign: 'center', maxWidth: 520, padding: '0 30px', margin: 'auto', marginBottom: 45, }}>
        <h1 style={{ fontFamily: 'Times New Roman', }}>Attach notes</h1>
        <p style={{ fontSize: 20 }}>Attach notes to every node manually or auto-generate them using AI with custom instructions</p>
      </div>
      <div style={{
        maxWidth: 900, margin: 'auto', marginBottom: 100,
        display: 'flex', justifyContent: 'center',
        padding: 16
      }}>
        <img
          src="/video/gif9.gif"
          style={{
            width: '100%',
            maxWidth: 900,
            height: '100%',
            padding: '4px',
            borderRadius: '12px',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px 0px'
            // objectFit: 'cover',
            // objectPosition: 'center'
          }}
        />
      </div>
    </div>
  )
}

export default HomePage