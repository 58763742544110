import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from "react-router-dom";

// import 'antd/dist/reset.css';
// import './index.css';
import App from './App';
// import App from './pages/mindmap/index.tsx'
import reportWebVitals from './reportWebVitals';

const referrerUrl = localStorage.getItem('referrerUrl');
if (!referrerUrl) {
  localStorage.setItem('referrerUrl', document.referrer);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <ConfigProvider
        theme={{
          // dark: true,
          token: {
              // fontFamily: 'Anonymous Pro',
              fontFamily: 'Outfit'
          },
        }}
      >
          <App />
      </ConfigProvider>
    </BrowserRouter>
);

reportWebVitals();
