import { useCallback, useRef, useEffect, useState, useContext } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import ReactFlow, {
  ConnectionLineType,
  NodeOrigin,
  Node,
  OnConnectEnd,
  OnConnectStart,
  useReactFlow,
  useStoreApi,
  Controls,
  Panel,
} from 'reactflow';
import shallow from 'zustand/shallow';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

import { get, patch, post } from '../../utils/fetch';
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from '../../actions';

import useStore, { RFState } from './store.ts';
import MindMapNode from './MindMapNode/index.tsx';
import MindMapEdge from './MindMapEdge/index.tsx';
import DetailModal from './DetailModal/index.tsx';
import MainMenu from '../../components/MainMenu.jsx';

// we need to import the React Flow styles to make it work
import 'reactflow/dist/style.css';
import React from 'react';
import { Button } from 'antd';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  modalId: state.modalId,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  addChildNode: state.addChildNode,
  openContentModal: state.openContentModal,
  ownerEmail: state.ownerEmail,
  reset: state.reset
});

const nodeTypes = {
  mindmap: MindMapNode,
};

const edgeTypes = {
  mindmap: MindMapEdge,
};

const nodeOrigin: NodeOrigin = [0.5, 0.5];

const connectionLineStyle = { stroke: '#F6AD55', strokeWidth: 3 };
const defaultEdgeOptions = { style: connectionLineStyle, type: 'mindmap' };

function Flow() {
  const history = useHistory()
  const { folderId, viewMode } = useParams();
  const { addAction, removeAction } = usePageActionHandler();
  const [projectData, setProjectData] = useState(null);
  const { setError, isActionInProgress } = useContext(PageContext);
  const userEmail = localStorage.getItem('email');
  const store = useStoreApi();
  const { nodes, edges, ownerEmail, onNodesChange, onEdgesChange, addChildNode, modalId , openContentModal} = useStore(
    selector,
    shallow
  );
  const isEditMindmapMode = viewMode === 'edit';
  const { setNodes, setEdges,setOwnerEmail } = useStore.getState();
  const { project } = useReactFlow();
  const connectingNodeId = useRef<string | null>(null);

  useEffect(() => {
    useStore.getState().reset()
    getProject();
  }, [])

  const getProject = async () => {
    const action = isEditMindmapMode ? "getMindmaps" : "publicMindmaps";
    addAction(action);
    await get(`${actionTypes[action].api}${folderId}/`, isEditMindmapMode)
      .then((res) => {
        setProjectData(res)
        const mapNodes = res.project_data.nodes.map((item) => ({
          dbId: item.dbId,
          ...item.flow_data
        }))
        setNodes(mapNodes);
        setEdges(res.project_data.relationships)
        setOwnerEmail(res.user.email)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const updateProject = async () => {
    const action = "getMindmaps";
    addAction(action);
    const newNodesData = nodes.map((item) => ({
      id: item.dbId || item.id,
      // title: item.data.label,
      parent: item.parentNode === 'root'
        ? nodes.find((item) => item.id === 'root').dbId
        : item.parentNode,
      flow_data: JSON.stringify({
        data: item.data,
        dragHandle: item.dragHandle,
        height: item.height,
        id: item.id,
        parentNode: item.parentNode,
        position: item.position,
        type: item.type,
        width: item.width,
        positionAbsolute: item.positionAbsolute
      })
    }))
    await patch(`${actionTypes[action].api}${folderId}/`, {
      nodes: newNodesData,
    })
      .then((res) => {
        
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const getChildNodePosition = (event: MouseEvent, parentNode?: Node) => {
    const { domNode } = store.getState();

    if (
      !domNode ||
      // we need to check if these properites exist, because when a node is not initialized yet,
      // it doesn't have a positionAbsolute nor a width or height
      !parentNode?.positionAbsolute ||
      !parentNode?.width ||
      !parentNode?.height
    ) {
      return;
    }

    const { top, left } = domNode.getBoundingClientRect();

    // we need to remove the wrapper bounds, in order to get the correct mouse position
    const panePosition = project({
      x: event.clientX - left,
      y: event.clientY - top,
    });

    // we are calculating with positionAbsolute here because child nodes are positioned relative to their parent
    return {
      x: panePosition.x - parentNode.positionAbsolute.x + parentNode.width / 2,
      y: panePosition.y - parentNode.positionAbsolute.y + parentNode.height / 2,
    };
  };

  const onConnectStart: OnConnectStart = useCallback((_, { nodeId }) => {
    // we need to remember where the connection started so we can add the new node to the correct parent on connect end
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd: OnConnectEnd = useCallback(
    (event) => {
      const { nodeInternals } = store.getState();
      const targetIsPane = (event.target as Element).classList.contains(
        'react-flow__pane'
      );
      const node = (event.target as Element).closest('.react-flow__node');

      if (node) {
        node.querySelector('input')?.focus({ preventScroll: true });
      } else if (targetIsPane && connectingNodeId.current) {
        const parentNode = nodeInternals.get(connectingNodeId.current);
        const childNodePosition = getChildNodePosition(event, parentNode);

        if (parentNode && childNodePosition) {
          addChildNode(parentNode, childNodePosition);
        }
      }
    },
    [getChildNodePosition]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodeOrigin={nodeOrigin}
      defaultEdgeOptions={defaultEdgeOptions}
      connectionLineStyle={connectionLineStyle}
      connectionLineType={ConnectionLineType.Straight}
      fitView
      // nodesDraggable={false}
      // nodesConnectable={isEditMindmapMode}
    >
      <Controls showInteractive={false} />
      {
        modalId && modalId !== '' && (
          <DetailModal isEditMindmapMode={isEditMindmapMode} modalId={modalId} updateProject={updateProject} openContentModal={openContentModal} />
        )
      }
      <Panel position="top-left">
        <div style={{ display: 'flex', gap: 6 }}>
        <MainMenu btnType="default" style={{ padding: 10 }} />
        <Button
          style={{ backgroundColor: 'white' }}
          onClick={() => {
            const rootNode = nodes.find((item) => item.id === 'root');

            setNodes([
              {
                ...(rootNode || {
                  dbId: uuidv4().replace(/-/g, '').slice(0, 20),
                  id: 'root',
                  type: 'mindmap',
                  data: { label: 'Title' },
                  position: { x: 0, y: 0 },
                  dragHandle: '.dragHandle',
                })
              },
            ])
          }}
        >
          Reset
        </Button>
        <Button
          style={{ backgroundColor: 'white' }}
          loading={isActionInProgress()}
          onClick={() => {
            if (userEmail === ownerEmail) {
              updateProject()
            } else {
              history.push("/signin")
            }
          }}
        >
          Save
        </Button>
        {
          isEditMindmapMode &&
          <Button
            style={{ backgroundColor: 'white' }}
            onClick={() => {
                history.push("/user/account")
            }}
          >
            {localStorage.getItem("aiModel") || "gpt-4o-mini"}
          </Button>
        }
        </div>
      </Panel>
    </ReactFlow>
  );
}

const Mindmap = () => {
  return (
    <ReactFlowProvider>
      <div style={{ backgroundColor: "#636363", height: '100vh', width: '100vw' }}><Flow /></div>
    </ReactFlowProvider>
  )
}

export default Mindmap;
