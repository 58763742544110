const actionTypes = {
  getMindmaps: {
    api: '/mindmaps/'
  },
  publicMindmaps: {
    api: '/public-mindmaps/'
  },
  nodes: {
    api: '/nodes/'
  },
  publicNodes: {
    api: '/public-nodes/'
  }
}

export {
  actionTypes
};
